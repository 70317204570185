import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import LoadingScreen from './components/LoadingScreen';

// Lazy load the App component
const App = React.lazy(() => import('./App'));

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>
);
